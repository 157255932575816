import React, { Component } from 'react'

export default class IconBed extends Component {
  render() {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.733 11.0773C3.49953 11.2247 3.4298 11.5335 3.57726 11.767C3.72471 12.0005 4.03352 12.0702 4.267 11.9227L3.733 11.0773ZM22.733 11.9227C22.9665 12.0702 23.2753 12.0005 23.4227 11.767C23.5702 11.5335 23.5005 11.2247 23.267 11.0773L22.733 11.9227ZM13.5 5.5L13.767 5.07726C13.6039 4.97425 13.3961 4.97425 13.233 5.07726L13.5 5.5ZM23.267 11.0773L13.767 5.07726L13.233 5.92274L22.733 11.9227L23.267 11.0773ZM4.267 11.9227L13.767 5.92274L13.233 5.07726L3.733 11.0773L4.267 11.9227Z" fill="#A9A9A9"/>
        <circle cx="10" cy="21" r="1.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="18" cy="21" r="1.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.5 21.5H6C5.17157 21.5 4.5 20.8284 4.5 20V20C4.5 18.067 6.067 16.5 8 16.5H19.5C21.1569 16.5 22.5 17.8431 22.5 19.5V20.5C22.5 21.0523 22.0523 21.5 21.5 21.5H19.5M11.5 21.5H16.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.9333 13.5H16.6667C17.2206 13.5 17.7295 13.8053 17.9902 14.2941L19.1667 16.5H8.83333L9.72745 14.8235C10.1619 14.0089 11.0101 13.5 11.9333 13.5Z" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
}