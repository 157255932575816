import React from 'react'
import Row from '../components/page-content-row'
import { Flex, Box } from '@rebass/grid'
import Subtitle from '../components/page-subtitle'
import { theme } from '../util/style'
import PageButtons from '../components/page-buttons'
import Copy from '../components/page-copy'

import Image from '../components/page-image-new'

const InclusionsLink =
  'https://f.hubspotusercontent00.net/hubfs/6193006/happyhaus-inclusions.pdf'

// const InclusionsLink = '/pdfs/happy-haus-inclusions.pdf'
const InclusionImage = 'designs/happy-haus-new-farm-3.jpg'

const Inclusions = data => {
  return (
    <Row>
      <Flex flexWrap="wrap">
        <Box width={[1, 4 / 12]} px={2} mr={[0, 90]} pb={[3, 0]}>
          <Image src={InclusionImage} alt="" />
        </Box>
        <Box width={[1, 7 / 12]} px={2} alignSelf="flex-start">
          <Subtitle align="left">Luxury inside and out</Subtitle>
          <Copy>
            <p>
              Our architects and designers specify equipment, finishes and
              detail to complement our homes. We choose trusted brands like
              Dulux, Colorbond and Ilve. We specify quality fittings and kitchen
              equipment that complement each home.
            </p>
          </Copy>
          <PageButtons
            align="left"
            data={[
              {
                label: 'Download our standard inclusions',
                link: InclusionsLink,
                external: true,
                background: theme.colors.grey1,
              },
            ]}
          />
        </Box>
      </Flex>
    </Row>
  )
}

export default Inclusions
