import React, { Component } from 'react'

export default class IconBed extends Component {
  render() {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 22.5V16.5C22.5 15.3954 21.6046 14.5 20.5 14.5H6.5C5.39543 14.5 4.5 15.3954 4.5 16.5V22.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.5 13.5V9.5C20.5 8.39543 19.6046 7.5 18.5 7.5H8.5C7.39543 7.5 6.5 8.39543 6.5 9.5V13.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5 14.5V12.5C13.5 11.9477 13.0523 11.5 12.5 11.5H7.5C6.94772 11.5 6.5 11.9477 6.5 12.5V14.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.5 14.5V12.5C20.5 11.9477 20.0523 11.5 19.5 11.5H14.5C13.9477 11.5 13.5 11.9477 13.5 12.5V14.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 19.5H22.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
}
