import React, { Component } from 'react'
import styled from 'styled-components'
import { theme } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import PageButtons from '../components/page-buttons'
import Image from '../components/page-image-new'

export default class HomeFlooplan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFloor: 0,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle(e, i) {
    this.setState({
      activeFloor: i,
    })
  }

  _renderToggles() {
    const { floors } = this.props.data
    const { activeFloor } = this.state
    const self = this
    const inner = floors.map(function(floor, i) {
      const key = `floor-${i}`
      return (
        <Toggle
          key={key}
          onClick={e => self.toggle(e, i)}
          isActive={i === activeFloor}
        >
          {floor.label}
        </Toggle>
      )
    })
    return <Toggles>{inner}</Toggles>
  }

  _renderStats() {
    const { stats } = this.props.data
    const inner = stats.map(function(stat, i) {
      const key = `stat-${i}`

      let value = stat.value
      let label = stat.label
      value = value.replace('m2', 'm<sup>2</sup>')

      let isBold = false
      if (
        label === 'Dimensions' ||
        label === 'Total area' ||
        label === 'Block'
      ) {
        isBold = true
      }

      if (label === 'Room dimensions (mm)') {
        return (
          <Stat isBold={isBold} key={key}>
            {stat.label}
          </Stat>
        )
      } else {
        return (
          <Stat isBold={isBold} key={key}>
            <span>{stat.label}</span>
            <StatNum dangerouslySetInnerHTML={{ __html: value }} />
          </Stat>
        )
      }
    })
    return <Stats>{inner}</Stats>
  }

  _renderImage() {
    const { activeFloor } = this.state
    const { floors } = this.props.data
    const w = floors[activeFloor].ratioWidth
      ? floors[activeFloor].ratioWidth
      : 1200
    const h = floors[activeFloor].ratioHeight
      ? floors[activeFloor].ratioHeight
      : 1200
    return (
      <FloorImage ratio={h / w}>
        <Image src={floors[activeFloor].image} alt="" />
      </FloorImage>
    )
  }

  render() {
    const toggles = this._renderToggles()
    const image = this._renderImage()
    const stats = this._renderStats()
    const { id, title, data } = this.props
    const { download } = data

    return (
      <Wrap>
        <Row id={id}>
          <Flex flexWrap="wrap">
            <Box width={[1, 2 / 3]} px={2}>
              {image}
            </Box>
            <Box width={[1, 1 / 3]} px={2}>
              {toggles}
              {stats}
              <PageButtons
                data={[
                  {
                    label: `Download ${title} floorplan`,
                    external: true,
                    link: download,
                  },
                ]}
              />
            </Box>
          </Flex>
        </Row>
      </Wrap>
    )
  }
}

const Wrap = styled.div``

const Toggles = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const Toggle = styled.div`
  display: block;
  padding: 20px;
  border: 1px solid ${theme.colors.grey4};
  position: relative;
  cursor: pointer;
  :last-child {
    border-left: none;
  }
  :first-child {
    border-left: 1px solid ${theme.colors.grey4} !important;
  }
  &:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0px;
    top: 0;
    background: ${props =>
      props.isActive ? theme.colors.grey1 : theme.colors.white};
  }
`

const Stats = styled.div`
  display: block;
  border-top: 1px solid ${theme.colors.grey4};
  border-bottom: 1px solid ${theme.colors.grey4};
  padding-top: 10px;
  margin-bottom: 2em;
`

const Stat = styled.div`
  display: flex;
  color: ${theme.colors.grey3};
  margin-bottom: 6px;
  padding-bottom: 4px;
  font-size: 93%;
  border-top: ${props =>
    props.isBold === true ? `1px solid ${theme.colors.grey4}` : `none`};
  padding-top: ${props => (props.isBold === true ? `10px` : `0px`)};
  margin-top: ${props => (props.isBold === true ? '20px' : '0px')};
  span {
    width: 50%;
    display: block;
  }
`

const StatNum = styled.span`
  text-align: right;
`

const FloorImage = styled.div``
