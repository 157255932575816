import React, { Component } from 'react'
import styled from "styled-components"
import { theme } from '../util/style'

export default class HomeSubNav extends Component {
  render() {
    return (
      <Wrap>
        <a href="#overview">Overview</a>
        <a href="#floorplans">Floorplans</a>
        <a href="#facades">Facades</a>
        <a href="#more">Inclusions</a>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  text-align: right;
  color: ${theme.colors.grey1};
  a {
    color: ${theme.colors.grey1};
    display: inline-block;
    margin: 0 0 0 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`
