import React, { Component } from 'react'
import styled from 'styled-components'
import { fontLight } from '../util/style'
import IconBed from './svg/icon-bed'
import IconBath from './svg/icon-bath'
import IconCar from './svg/icon-car'
import { Box } from '@rebass/grid'

export default class RangeThumbDetail extends Component {
  render() {
    const { data } = this.props
    const { stats } = data

    return (
      <Details>
        <Box mr={'15px'}>
          <span>{data.completion}</span>
        </Box>
        <StatIcon>
          <IconBed />
        </StatIcon>
        <Stat length={stats.bed.length}>{stats.bed}</Stat>
        <StatIcon>
          <IconBath />
        </StatIcon>
        <Stat length={stats.bath.length}>{stats.bath}</Stat>
        <StatIcon>
          <IconCar />
        </StatIcon>
        <Stat length={stats.car.length}>{stats.car}</Stat>
        <DetailItemAlt>
          <span>{stats.size}</span>
        </DetailItemAlt>
      </Details>
    )
  }
}

const Details = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }
`

const DetailItemAlt = styled.span`
  display: flex;
  justify-content: space-between;

  font-size: 20px;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`

const StatIcon = styled.span`
  position: relative;
  width: 19px;
  margin-right: 9px;

  @media (max-width: 800px) {
    margin-right: 10px;
  }

  svg {
    position: absolute;
    left: 2px;
    top: -16px;
  }
`

const Stat = styled.span`
  font-size: 20px;
  padding-left: 4px;
  margin-right: 10px;

  display: inline-block;
  width: ${(props) =>
    props.length ? (props.length > 1 ? `40px` : `20px`) : `20px`};

  position: relative;

  @media (max-width: 800px) {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    font-size: 18px;
  }
`
