import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import HomeFacades from '../components/home-facades'
import HomeFloorplan from '../components/home-floorplan'
import PageHeroGallery from '../components/page-hero-gallery'
import RangeThumbDetail from '../components/range-thumb-detail'
import PageCards from '../components/page-cards'
import HomeSubNav from '../components/home-subnav'
import HomeInclusions from '../components/home-inclusions'
import PageOverview from '../components/page-overview'
import Image from '../components/page-image-new'
import styled from 'styled-components'
import { theme } from '../util/style'

export default class HomeTemplate extends Component {
  render() {
    const { data } = this.props
    const {
      storey,
      facades,
      hero,
      summary,
      stats,
      floorplan,
      title,
      overview,
      description,
    } = data.home
    const { allImages } = data

    let image
    let ogImages = []

    if (hero && hero.length >= 0) {
      for (let i = 0; i < hero.length; i++) {
        ogImages.push(hero[i].image)
      }
    }

    return (
      <>
        <SEO
          title={`${title} ${
            storey.charAt(0).toUpperCase() + storey.slice(1)
          } Storey Home Design`}
          description={description}
          image={hero[0].image}
          images={ogImages}
        />

        <Row>
          <Flex flexWrap="nowrap">
            <Box width={1} px={2}>
              <Title align="left" mega={true}>
                {title}
              </Title>
            </Box>
          </Flex>
          <Flex flexWrap="nowrap" alignItems="flex-end">
            <Box width={[1, 5 / 12]} px={2}>
              <Subtitle>{summary}</Subtitle>
            </Box>
            <SubNavBox width={[1, 7 / 12]} px={2}>
              <HomeSubNav />
            </SubNavBox>
          </Flex>
        </Row>

        <PageHeroGallery slides={hero} ratio={1 / 1.5} />

        <Row id="overview">
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 2]} px={2}>
              <RangeThumbDetail data={{ storey, title, stats }} />
              <Copy>
                <div dangerouslySetInnerHTML={{ __html: overview.copy }} />
              </Copy>
            </Box>

            <Box width={[1, 1.3 / 3]} px={2} ml={[0, 82]}>
              <Image src={overview.image} alt="img" />
            </Box>
          </Flex>
        </Row>

        <HomeFloorplan id="floorplans" data={floorplan} title={title} />

        <HomeInclusions />

        <HomeFacades
          id="facades"
          facades={facades}
          allImages={allImages}
          allFacades={data.facades}
          thumbs={3}
          title={title}
        />
        <PageCards id="more" />
      </>
    )
  }
}

export const query = graphql`
  query ($slug: String!) {
    home: homesJson(id: { eq: $slug }) {
      id
      title
      storey
      thumb
      summary
      description
      hero {
        image
      }
      stats {
        bed
        bath
        car
        price
        size
      }
      floorplan {
        stats {
          label
          value
        }
        floors {
          label
          image
          ratioWidth
          ratioHeight
        }
        download
      }
      overview {
        copy
        image
      }
      facades
    }
    facades: allFacadesJson {
      edges {
        node {
          id
          title
          image
          storey
        }
      }
    }
    allImages: allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`

const SubNavBox = styled(Box)`
  @media (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
`

const Subtitle = styled.h2`
  color: ${theme.colors.grey3};
  font-weight: 100;
  text-align: left;
  font-size: 130%;
  margin: 0;
  line-height: 140%;
  @media (max-width: 800px) {
    font-size: 120%;
    line-height: 140%;
  }
  p {
    margin-top: 0%;
    margin-bottom: 1em;
    width: 90%;
  }
  a {
    color: ${theme.colors.grey3};
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      color: ${theme.colors.grey1};
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
