import React, { Component } from 'react'
import styled from 'styled-components'
import { theme } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Subtitle from './page-subtitle'
import { imgUrl } from '../util/helpers'
import PageButtons from './page-buttons'
import Lightbox from 'react-images'
import _ from 'lodash'
import Image from '../components/page-image-new'

export default class HomeFacades extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLightbox: false,
      currImage: 0,
    }
    this._onView = this._onView.bind(this)
    this._onPrev = this._onPrev.bind(this)
    this._onNext = this._onNext.bind(this)
    this._onClose = this._onClose.bind(this)
    this._gotoImage = this._gotoImage.bind(this)
  }

  _onView() {
    this.setState({
      isLightbox: true,
    })
  }

  _onNext() {
    const { currImage } = this.state
    const { facades } = this.props
    const index = currImage + 1 === facades.length ? 0 : currImage + 1
    this.setState({
      currImage: index,
    })
  }

  _onPrev() {
    const { currImage } = this.state
    const { facades } = this.props
    const index = currImage === 0 ? facades.length - 1 : currImage - 1
    this.setState({
      currImage: index,
    })
  }

  _onClose() {
    this.setState({
      isLightbox: false,
      currImage: 0,
    })
  }

  _gotoImage(index) {
    this.setState({
      isLightbox: true,
      currImage: index,
    })
  }

  _getLightbox(data) {
    const { isLightbox, currImage } = this.state
    const { facades, allImages, allFacades } = this.props
    const homeFacades = allFacades.edges.filter(facade => {
      const facadeId = facade.node.id
      return facades.indexOf(facadeId) > -1
    })
    const facadeImages = homeFacades.map(facade => {
      const facadeImage = facade.node.image
      const foundImages = allImages.edges.filter(image => {
        return image.node.relativePath === facadeImage
      })
      if (foundImages.length > 0) {
        return { src: foundImages[0].node.publicURL }
      }
    })

    return (
      <Lightbox
        currentImage={currImage}
        images={facadeImages}
        isOpen={isLightbox}
        onClickPrev={this._onPrev}
        onClickNext={this._onNext}
        onClose={this._onClose}
        backdropClosesModal={true}
      />
    )

    // return (
    //   <Lightbox
    //     currentImage={currImage}
    //     images={imgs}
    //     isOpen={isLightbox}
    //     onClickPrev={this._onPrev}
    //     onClickNext={this._onNext}
    //     onClose={this._onClose}
    //     backdropClosesModal={true}
    //   />
    // )
  }

  _getFacades(data) {
    const self = this

    return data.map(function(edge, i) {
      const facade = edge.node
      const key = `facade-${i}`
      if (i < 4) {
        return (
          <Box key={key} width={1 / 3} px={2}>
            <Thumb onClick={e => self._gotoImage(i)}>
              <ImageWrap>
                <Image src={imgUrl(facade.image, 'w_500')} alt={facade.title} />
              </ImageWrap>
              <Title>{facade.title}</Title>
            </Thumb>
          </Box>
        )
      } else {
        return null
      }
    })
  }

  render() {
    const { facades, allFacades } = this.props
    const filtered = _.filter(allFacades.edges, (facade, i) => {
      return facades.indexOf(facade.node.id) > -1
    })

    const facadeThumbs = this._getFacades(filtered)
    const lightbox = this._getLightbox(filtered)

    const { title, id } = this.props
    return (
      <Wrap>
        <Row id={id}>
          <Flex>
            <Box width={1} px={2}>
              <Subtitle>Feature Facades for {title}</Subtitle>
            </Box>
          </Flex>
          <Flex flexWrap="wrap">{facadeThumbs}</Flex>
        </Row>
        <Row>
          <Flex>
            <Box width={1} px={2}>
              <PageButtons
                align="left"
                data={[
                  {
                    label: 'View all Facades',
                    link: '/forms',
                    background: theme.colors.grey1,
                  },
                ]}
              />
            </Box>
          </Flex>
        </Row>
        {lightbox}
      </Wrap>
    )
  }
}

const Wrap = styled.div``

const ImageWrap = styled.div`
  margin-bottom: 10px;
  /* img {
    display: block;
  } */
`

const Thumb = styled.div`
  cursor: pointer;
`

const Title = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${theme.colors.grey4};
`
