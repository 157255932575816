import React, { Component } from 'react'

export default class IconBed extends Component {
  render() {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 9.49313C11.5 7.28779 13.2909 5.5 15.5 5.5C17.7091 5.5 19.5 7.28779 19.5 9.49313V22.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.54124 12.5C7.81633 10.8422 9.43777 9.5 11.5 9.5C13.5622 9.5 15.1837 10.8422 15.4588 12.5H7.54124Z" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.5 15V17" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.5 19.5V21.5" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.27637 15.0439L8.61322 16.8659" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.87012 19.5L7.20697 21.322" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.6602 15.0439L14.3233 16.8659" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.1997 19.5L15.8629 21.322" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
}
